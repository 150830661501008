/*
Copyright 2021 The Matrix.org Foundation C.I.C.

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

import {EventEntry} from "./EventEntry.js";

// EventEntry but without the two properties that are populated via SyncWriter
// Useful if you want to create an EventEntry that is ephemeral

export class NonPersistedEventEntry extends EventEntry {
    get fragmentId() {
        throw new Error("Cannot access fragmentId for non-persisted EventEntry");
    }

    get entryIndex() {
        throw new Error("Cannot access entryIndex for non-persisted EventEntry");
    }

    get isNonPersisted() {
        return true;
    }

    // overridden here because we reuse addLocalRelation() for updating this entry
    // we don't want the RedactedTile created using this entry to ever show "is being redacted"
    get isRedacting() {
        return false;
    }

    get isRedacted() {
        return super.isRedacting;
    }
}
